import axios from 'axios'
import settings from '../settings/apis.js'

class SportsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].sportsApiUrl + "/",
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getPendingResults(startDate, endDate) {
        return this.Api.get('/results/getPendingResults/' + startDate + '/' + endDate);
    }
    tryGetResults(fixtureId) {
        return this.Api.get('/results/setResults/' + fixtureId);
    }
    getFixtureDetails(fixtureId, isTopOffer) {
        return this.Api.get('/settings/getFixture/' + fixtureId + "/" + isTopOffer);
    }
    getOutrightDetails(fixtureId) {
        return this.Api.get('/settings/getOutright/' + fixtureId);
    }
    getResultsSports(startDate, endDate) {
        return this.Api.get('/results/getSports/' + startDate + '/' + endDate)
    }
    getResultsCategories(sportId, startDate, endDate) {
        return this.Api.get('/results/getCategories/' + sportId + '/' + startDate + '/' + endDate)
    }
    getResultsTournaments(sportId, categoryId, startDate, endDate) {
        return this.Api.get('/results/getTours/' + sportId + '/' + categoryId + '/' +
            startDate + '/' + endDate)
    }
    getResults(sportId, categoryId, tournamentId, startDate, endDate) {
        return this.Api.get('/results/getResults/' + sportId + '/' + categoryId + '/' +
            tournamentId + '/' + startDate + '/' + endDate)
    }
    getOutrightsSports() {
        return this.Api.get('/offer/getOutrightsSports')
    }
    getOutrightsResultsSports(startDate, endDate) {
        return this.Api.get('/results/getOutrightsSports/' + startDate + '/' + endDate)
    }
    getOutrightsResults(startDate, endDate, sportId) {
        return this.Api.get('/results/getOutrightResults/' + sportId + '/' + startDate + '/' + endDate)
    }
    getSports(startDate, endDate) {
        return this.Api.get('/offer/getSports/' + startDate + '/' + endDate + '/true')
    }
    getCategories(sportId, startDate, endDate) {
        return this.Api.get('/offer/getCategories/' + sportId + '/' + startDate + '/' + endDate + '/true')
    }
    getTournaments(sportId, categoryId, startDate, endDate) {
        return this.Api.get('/offer/getTours/' + sportId + '/' + categoryId + '/' +
            startDate + '/' + endDate + '/true')
    }
    getFixtures(sportId, categoryId, tournamentId, startDate, endDate) {
        return this.Api.get('/settings/getCurrentOffer/' + sportId + '/' + categoryId + '/' +
            tournamentId + '/' + startDate + '/' + endDate)
    }
    getOutrights(sportId) {
        return this.Api.get('/settings/getOutrightsCurrentOffer/' + sportId)
    }
    getTicketsBySerials(params) {
        var paramsObj = {
            page: params.page,
            per_page: params.per_page,
            sort: params.sort,
            status: params.status,
            ticketSerial: params.ticketSerial,
            typeFilter: params.typeFilter
        }
        return this.Api.post('/tickets/getTicketsFromSerials', paramsObj);
    }
}

export {SportsApi}
