<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.sportsResults') }}</h5>
                <Toast />
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel :header="$t('sportsbets.fixtures')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="categoriesModel" v-show="showCategoryDdl" :options="categories" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.category')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="tournamentsModel" v-show="showTournamentsDdl" :options="tournaments" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.tournament')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-text-right"><Button v-show="showFetchBtn" :label="$t('buttons.showResults')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                        <DataTable
                            v-show="showFixturesTable"
                            :value="items"
                            :paginator="true"
                            :rows="perPage"
                            :loading="loading"
                            class="p-mt-3"
                            ref="datatable"
                            responsiveLayout="scroll"
                            :paginatorBottom="true"
                            stateStorage="session"
                            stateKey="dt-state-lottoresults"
                        >
                            <template #header> </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>
                            <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                            <Column field="date" :header="$t('sportsbets.date')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.date) }} </template>
                            </Column>
                            <Column field="tournament.name" :header="$t('sportsSettings.tournament')" :sortable="true">
                                <template #body="{ data }">{{ tournamentsDictionary[data.tournamentId] }}</template>
                            </Column>

                            <Column field="name" :header="$t('sportsSettings.name')"> </Column>
                            <Column field="results" :header="$t('results.finalResult')">
                                <template #body="{ data }">{{ notNullOrEmptyObject(data.results) ? data.results.homeScore + ' - ' + data.results.awayScore : '' }}</template></Column
                            >
                            <Column field="results" :header="$t('results.firstPeriodResult')">
                                <template #body="{ data }">{{ getPeriodResults(1, data.results) }} </template></Column
                            >
                            <Column field="results" :header="$t('results.resultsDate')" :sortable="true">
                                <template #body="{ data }">{{ notNullOrEmptyObject(data.results) && notNullOrEmptyObject(data.results.dateAdd) ? formatCSDateAndTime(data.results.dateAdd) : '' }}</template></Column
                            >
                            <Column field="results" :header="$t('results.resultAddedBy')">
                                <template #body="{ data }">{{ notNullOrEmptyObject(data.results) && notNullOrEmptyObject(data.results.addedBy) ? data.results.addedBy : '' }}</template></Column
                            >
                            <Column field="marketsCount" :header="$t('sportsSettings.marketsNo')"> </Column>
                            <Column field="settledMarketsCount" :header="$t('results.settledMarketsNo')"> </Column>
                            <Column>
                                <template #body="{ data }">
                                    <Button :label="$t('buttons.showTickets')" class="p-button-text p-button-plain p-button-info p-mr-2 p-mb-2" @click="goto('sportBetsTickets', { backRoute: this.crtRoute, redirectFixtureId: data.fixtureId })" />
                                </template>
                            </Column>
                            <Column :exportable="false" class="p-text-right">
                                <template #body="{ data }">
                                    <Button
                                        icon="pi pi-pencil"
                                        class="p-button-rounded p-button-warning p-mr-2"
                                        @click="goto('eventResults', { id: data.fixtureId, fixtureId: data.fixtureId, isResults: true, fixturesActive: fixturesActive, backRoute: crtRoute })"
                                    />
                                </template>
                            </Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel :header="$t('sportsbets.outrights')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-4 p-text-right"><Button v-show="showFetchBtn" :label="$t('buttons.showResults')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                        <DataTable
                            v-show="showOutrightsTable"
                            :value="itemsOutrights"
                            :paginator="true"
                            :rows="perPage"
                            :loading="loading"
                            class="p-mt-3"
                            ref="datatable"
                            responsiveLayout="scroll"
                            :paginatorBottom="true"
                            stateStorage="session"
                            stateKey="dt-state-betsresults"
                        >
                            <template #header> </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                            <Column field="date" :header="$t('sportsbets.startDate')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.startDate) }} </template>
                            </Column>
                            <Column field="date" :header="$t('sportsbets.endDate')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.endDate) }} </template>
                            </Column>

                            <Column field="name" :header="$t('sportsSettings.name')"> </Column>
                            <Column :exportable="false" class="p-text-right">
                                <template #body="{ data }">
                                    <Button
                                        icon="pi pi-pencil"
                                        class="p-button-rounded p-button-warning p-mr-2"
                                        @click="goto('eventResults', { id: data.id, fixtureId: data.id, isResults: true, fixturesActive: fixturesActive, backRoute: crtRoute })"
                                    />
                                </template>
                            </Column>
                        </DataTable>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsApi } from '../../service/SportsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
export default {
    name: 'LottoResults',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            itemsOutrights: [],
            SportsApi: new SportsApi(),
            crtPage: 0,
            startDateModel: null,
            endDateModel: null,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            showOkAlert: false,
            showErrorAlert: false,
            validatedTickets: '',
            showResultsLink: [],
            crtRoute: 'sportsResults',
            sports: [],
            sportsModel: {},
            categories: [],
            categoriesModel: {},
            tournaments: [],
            tournamentsModel: {},
            showFetchBtn: false,
            tournamentsDictionary: [],
            showCategoryDdl: false,
            showTournamentsDdl: false,
            filtersInitializationDone: false,
            defaultEntityId: 0,
            showFixturesTable: false,
            activeTab: 0,
            showOutrightsTable: false,
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        startDateApi() {
            return this.formatApiDate(this.startDateModel);
        },
        endDateApi() {
            return this.formatApiDate(this.endDateModel);
        },
        fixturesActive() {
            return this.activeTab == 0;
        },
    },
    watch: {
        activeTab() {
            this.getSports();
        },
        startDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = {};
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        endDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = {};
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        sportsModel() {
            this.clearFixturesTable();
            this.clearCategoriesDdl();
            this.clearTournamentsDdl();
            this.showTournamentsDdl = false;
            this.showCategoryDdl = false;
            this.showFetchBtn = true;
            this.showFixturesTable = false;

            if (this.notNullOrEmptyObject(this.sportsModel)) {
                if (this.fixturesActive) {
                    this.getCategories();
                } else {
                    this.showFetchBtn = true;
                    this.showCategoryDdl = false;
                    this.showTournamentsDdl = false;
                }
            }
        },
        categoriesModel() {
            this.clearFixturesTable();
            this.clearTournamentsDdl();
            this.showFixturesTable = false;
            this.showTournamentsDdl = false;
            if (this.notNullOrEmptyObject(this.categoriesModel)) {
                this.showFetchBtn = true;
                this.getTournaments();
            }
        },
        tournamentsModel() {
            if (this.notNullOrEmptyObject(this.tournamentsModel)) {
                this.readItems();
            }
        },
    },
    mounted() {
        if (this.storeFilterData != undefined && this.notNullOrEmptyField(this.storeFilterData.data.startDate) && this.notNullOrEmptyField(this.storeFilterData.data.endDate)) {
            this.startDateModel = new Date(this.storeFilterData.data.startDate);
            this.endDateModel = new Date(this.storeFilterData.data.endDate);
        } else {
            let crtDate = new Date();
            let year = crtDate.getFullYear();
            let month = crtDate.getMonth() + 1;
            if (month <= 9) {
                month = '0' + month;
            }
            let day = crtDate.getDate();
            if (day <= 9) {
                day = '0' + day;
            }
            var startDateString = year + '-' + month + '-' + day + 'T00:00';
            var endDateString = year + '-' + month + '-' + day + 'T23:59';
            this.startDateModel = new Date(startDateString);
            this.endDateModel = new Date(endDateString);
        }
        this.getSports();
    },
    methods: {
        composeAndSaveFiltersToStore() {
            let filters = {
                startDate: this.startDateModel,
                endDate: this.endDateModel,
                sportId: this.notNullOrEmptyObject(this.sportsModel) ? this.sportsModel.id : this.defaultEntityId,
                categoryId: this.notNullOrEmptyObject(this.categoriesModel) ? this.categoriesModel.id : this.defaultEntityId,
                tournamentId: this.notNullOrEmptyObject(this.tournamentsModel) ? this.tournamentsModel.id : this.defaultEntityId,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        readItems() {
            this.loading = true;
            if (this.fixturesActive) {
                var tournamentId = this.notNullOrEmptyObject(this.tournamentsModel) ? this.tournamentsModel.id : this.defaultEntityId;
                this.SportsApi.getResults(this.sportsModel.id, this.categoriesModel.id, tournamentId, this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.composeAndSaveFiltersToStore();
                        this.items = response.data;
                        this.filtersInitializationDone = true;
                        this.loading = false;
                        this.showFixturesTable = true;
                    })
                    .catch(() => {
                        this.loading = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.SportsApi.getOutrightsResults(this.startDateApi, this.endDateApi, this.sportsModel.id)
                    .then((response) => {
                        this.itemsOutrights = response.data;
                        this.showOutrightsTable = true;
                        this.loading = false;
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            }
        },
        createTournamentsDictionary() {
            for (let i = 0; i < this.tournaments.length; i++) {
                this.tournamentsDictionary[this.tournaments[i].id] = this.tournaments[i].displayName;
            }
        },
        getSports() {
            this.showFetchBtn = false;
            if (this.fixturesActive) {
                this.SportsApi.getResultsSports(this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.sports = response.data;
                        if (!this.filtersInitializationDone && this.storeFilterData != undefined) {
                            this.sportsModel = this.sports.find((s) => s.id == this.storeFilterData.data.sportId);
                        } else {
                            this.filtersInitializationDone = true;
                        }
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.SportsApi.getOutrightsResultsSports(this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.sports = response.data;
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            }
        },
        showToast(toastSeverity) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: this.resultsMessage, life: this.getToastLifeSeconds() });
        },
        getCategories() {
            this.showFetchBtn = false;
            this.showCategoryDdl = false;
            this.showTournamentsDdl = false;
            this.SportsApi.getResultsCategories(this.sportsModel.id, this.startDateApi, this.endDateApi)
                .then((response) => {
                    this.categories = response.data;
                    this.showCategoryDdl = true;
                    this.showTournamentsDdl = false;
                    if (!this.filtersInitializationDone && this.storeFilterData != undefined) {
                        this.categoriesModel = this.categories.find((s) => s.id == this.storeFilterData.data.categoryId);
                    } else {
                        this.filtersInitializationDone = true;
                    }
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        getTournaments() {
            this.showTournamentsDdl = false;
            this.SportsApi.getResultsTournaments(this.sportsModel.id, this.categoriesModel.id, this.startDateApi, this.endDateApi)
                .then((response) => {
                    this.tournaments = response.data;
                    this.showTournamentsDdl = true;
                    this.createTournamentsDictionary();
                    if (!this.filtersInitializationDone && this.storeFilterData != undefined) {
                        this.tournamentsModel = this.tournaments.find((s) => s.id == this.storeFilterData.data.tournamentId);
                    }
                    this.filtersInitializationDone = true;
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        clearFixturesTable() {
            this.items = [];
            this.showFixturesTable = false;
        },
        clearCategoriesDdl() {
            this.categories = [];
            this.categoriesModel = {};
        },
        clearTournamentsDdl() {
            this.tournaments = [];
            this.tournamentsModel = {};
        },
        getPeriodResults(periodId, results) {
            if (this.notNullOrEmptyObject(results) && this.notNullOrEmptyObject(results.periods) && this.notNullOrEmptyField(results.periods.length)) {
                for (var i = 0; i < results.periods.lenght; i++);
                {
                    if (results.periods[0].number == periodId) {
                        return results.periods[0].homeScore + ' - ' + results.periods[0].awayScore;
                    }
                }
            }
            return '';
        },
    },
};
</script>
